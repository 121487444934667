import React, {Component} from 'react';
import client from '../feathers';
import FlipMove from 'react-flip-move';

import backIcon from '../assets/icons/back.svg'
import NoImg from '../assets/img/noimg.png'
import {Link} from 'react-router-dom'

export default class VoteECO extends Component {
    constructor(props){
        super(props);
        this.model = client.service('eco');
        this.state = {
            items: [],
            filtered: [],
            form: {
                name: '',
                name_ar: '',
                votes: 0,
                image: '',
            }
        };
    }

    componentDidMount(){
        this.model.find({
            query: {
                $sort: { name: 1 },
                $limit: 9999
            }
        }).then(items => {
            // console.log('items', items)
            this.setState({
                items: items.data,
                filtered: items.data
            })
        })

        this.model.on('created', item => {
            // console.log('ON CREATED')
            this.setState({
                items: this.state.items.concat(item),
                filtered: this.state.items.concat(item)
            })
        })

        this.model.on('patched', item =>{
            // console.log('PATCHED', item);
            const arr = [...this.state.items]
            const i = arr.findIndex(e => e._id === item._id)
            if(i !== -1){
                arr[i] = item;
                this.setState({items: arr, filtered: arr})
            }
        })

        this.model.on('removed', item => {
            // console.log('RM item', item)
            const arr = [...this.state.items]
            const i = arr.findIndex(e => e._id === item._id)
            if(i !== -1){
                arr.splice(i, 1);
                this.setState({items: arr, filtered: arr})
            }
        })
    }

    handleSearch(e) {
        let currentList = [];
        let newList = [];

        if (e.target.value !== "") {
            currentList = this.state.items;
            newList = currentList.filter(item => {
                const name = item.name.toLowerCase();
                const ar = item.name_ar.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return name.includes(filter) || ar.includes(filter);
            });
        } else {
            newList = this.state.items;
        }
        this.setState({ filtered: newList });
    }

    async add_vote(id){
        const item = await this.model.get(id);
        let _votes = item.votes || 0;
        _votes = parseInt(_votes, 10) || 0;
        _votes = _votes + 1;
        await this.model.patch(id, {votes: _votes})
    }

    async rem_vote(id){
        const item = await this.model.get(id);
        let _votes = item.votes || 0;
        _votes = parseInt(_votes, 10) || 0;
        _votes = _votes - 1;
        if(_votes < 0) _votes = 0; 
        await this.model.patch(id, {votes: _votes})
    }

    render () {
        const { filtered } = this.state;
        return (
            <div className="container">
                <h1>
                    Supreme Committee of Financial Supervision Candidates (اعضاء اللجنة العليا للرقابة المالية)
                    <Link to="/vote" className="svgicon float-right"><img src={backIcon} alt="" /></Link>
                </h1>

                <div className="alert alert-info">
                    Click on candidate to vote UP, click the ▽ to down vote
                </div>

                <input type="text" className="form-control mb-3" placeholder="Search..." onChange={this.handleSearch.bind(this)} />

                <FlipMove
                    duration={750}
                    staggerDelayBy={20}
                    appearAnimation="accordionVertical"
                    easing="cubic-bezier(.12,.36,.14,1.2)" 
                >
                    
                    {filtered.map(item => (
                        <div className="vote-cand rlt" key={item._id}>

                            <button className="vote-decr" onClick={() => this.rem_vote(item._id)}>▽</button>
                        
                            <div className="vote-cand-inner row align-items-center" onClick={() => this.add_vote(item._id)}>
                                <div className="col-2">
                                    <h2 className="m-0">{item.votes}</h2>
                                </div>
                                
                                <div className="col text-right">
                                    {/* <h3 className="m-0">{item.name}</h3> */}
                                    <h3 className="m-0">{item.name_ar}</h3>
                                </div>
                                

                                <div className="col-thumb-sm">
                                    <div className="thumb-sm">
                                        <img src={item.image || NoImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    ))}
                </FlipMove>
            </div>
            
        );
    }
}

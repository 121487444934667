import io from 'socket.io-client';
import feathers from '@feathersjs/client';

// const socket = io('http://localhost:3030');
// const socket = io('http://192.168.0.104:3030');
const socket = io('https://kataeb-elections-backend-2019-vjnkhbnnwj.now.sh/');

const client = feathers();

client.configure(feathers.socketio(socket, {
  timeout: 30000
}));
client.configure(feathers.authentication({
  storage: window.localStorage
}));

export default client;

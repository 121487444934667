import React, {Component} from 'react';
import client from '../feathers';
import FlipMove from 'react-flip-move';
import NoImg from '../assets/img/noimg.png'
import Logo from '../assets/img/logo-white.png'
import BitwizeLogo from '../assets/img/bitwize-logo-white.svg'

export default class BoardPB extends Component {
    constructor(props){
        super(props);
        this.model = client.service('pb');
        this.state = {
            items: [],
            filtered: [],
            needed: 16
        };
    }

    componentDidMount(){
        this.model.find({
            query: { $sort: { votes: -1, name_ar: 1 }, $limit: 9999 }
        }).then(items => {
            this.setState({ items: items.data, filtered: items.data })
        })

        this.model.on('created', item => {
            this.setState({
                items: this.state.items.concat(item),
                filtered: this.state.items.concat(item)
            })
        })

        this.model.on('patched', item =>{
            const arr = [...this.state.items]
            const i = arr.findIndex(e => e._id === item._id)
            if(i !== -1){
                arr[i] = item;
                this.setState({items: arr, filtered: arr})
            }
        })

        this.model.on('removed', item => {
            const arr = [...this.state.items]
            const i = arr.findIndex(e => e._id === item._id)
            if(i !== -1){
                arr.splice(i, 1);
                this.setState({items: arr, filtered: arr})
            }
        })
    }

    render () {
        const { filtered, needed } = this.state;
        return (
            <div className="full-height ppw">
                <a className="bitwize" href="https://bitwize.com.lb" target="_blank" rel="noopener noreferrer"><img src={BitwizeLogo} alt="" /></a>
            
                <div className="row full-height">
                    

                    <div className="col bg-green">

                        <div className="py-3">
                            <FlipMove
                                duration={750}
                                staggerDelayBy={150}
                                appearAnimation="accordionVertical"
                                easing="cubic-bezier(.12,.36,.14,1.2)" 
                            >
                                {filtered.sort((a, b) => b.votes - a.votes).map((item, index) => (
                                    <div className={`vote-cand ${(index >= needed) ? 'loser' : ''}`} key={item._id}>
                                        <div className="vote-cand-inner row align-items-center">
                                            <div className="rank">
                                                {index + 1}
                                            </div>
                                            <div className="col-2">
                                                <h2 className="m-0">{item.votes}</h2>
                                            </div>
                                                    
                                            <div className="col text-right">
                                                {/* <h3>{item.name}</h3> */}
                                                <h3 className="m-0">{item.name_ar}</h3>
                                            </div>

                                            <div className="col-thumb-sm">
                                                <div className="thumb-sm">
                                                    <img src={item.image || NoImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </FlipMove>
                        </div>

                    </div>

                    <div className="col-3 bg-grey white text-center">
                        <div className="full-height d-flex align-items-center"><div>
                            <img src={Logo} alt="" style={{maxWidth: 300}} />
                            <h1 className="text-xxl mt-5">نتائج الانتخابات</h1>
                            <h2>المكتب السياسي</h2>
                        </div></div>
                    </div>
                </div>

                

            </div>

        );
    }
}

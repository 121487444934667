import React, {Component} from 'react';
import client from '../feathers';
import ImageUploader from '../Components/Upload';
import FlipMove from 'react-flip-move';

export default class Home extends Component {
    constructor(props){
        super(props);
        this.pb = client.service('pb');
        this.state = {
            pb: [],
            filtered: [],
            form: {
                name: '',
                name_ar: '',
                votes: 0,
                image: '',
            },
            pictures: []
        };
        // console.log(props);
    }

    componentDidMount(){
        this.pb.find({
            query: {
                $sort: { votes: -1 },
                $limit: 9999
            }
        }).then(items => {
            // console.log('items', items)
            this.setState({
                pb: items.data,
                filtered: items.data,
            })
        })
        this.pb.on('created', item => {
            // console.log('ON CREATED')
            this.setState({
                pb: this.state.pb.concat(item),
                filtered: this.state.pb.concat(item)
            })
        })

        this.pb.on('patched', item =>{
            // console.log('PATCHED', item);
            const arr = [...this.state.pb]
            const i = arr.findIndex(e => e._id === item._id)
            if(i !== -1){
                arr[i] = item;
                this.setState({pb: arr, filtered: arr})
            }
        })

        this.pb.on('removed', item => {
            // console.log('RM item', item)
            const arr = [...this.state.pb]
            const i = arr.findIndex(e => e._id === item._id)
            if(i !== -1){
                arr.splice(i, 1);
                this.setState({pb: arr, filtered: arr})
            }
        })
    }

    handleSearch(e) {
        let currentList = [];
        let newList = [];

        if (e.target.value !== "") {
            currentList = this.state.pb;
            newList = currentList.filter(item => {
                const name = item.name.toLowerCase();
                const ar = item.name_ar.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return name.includes(filter) || ar.includes(filter);
            });
        } else {
            newList = this.state.pb;
        }
        this.setState({ filtered: newList });
    }

    

    async remove_pb(id){
        await this.pb.remove(id);
    }

    async add_vote(id){
        const item = await this.pb.get(id);
        let _votes = item.votes || 0;
        _votes = parseInt(_votes, 10) || 0;
        _votes = _votes + 1;
        await this.pb.patch(id, {votes: _votes})
    }

    async rem_vote(id){
        const item = await this.pb.get(id);
        let _votes = item.votes || 0;
        _votes = parseInt(_votes, 10) || 0;
        _votes = _votes - 1;
        if(_votes < 0) _votes = 0; 
        await this.pb.patch(id, {votes: _votes})
    }

    changeHandler = ev => {
        const { name, value } = ev.target;
        const { form } = this.state;
        form[name] = value;
        this.setState({ form })
    }

    async add_pb(ev) {
        ev.preventDefault();
        console.log('FORM', this.state.form);
        await this.pb.create(this.state.form)
        this.setState({ form: {
            name: '',
            name_ar: '',
            votes: 0,
            image: '',
        } })
        this.refs.ImageUploader.reset();
    }

    async onDrop(pictureFiles, pictureDataURLs) {
		// await this.setState({ pictures: this.state.pictures.concat(pictureDataURLs) });
        const { form } = this.state;
        form.image = pictureDataURLs[0];
        await this.setState({ form })
	}

    render () {
        const { filtered, form } = this.state;
        return (
            <div className="container">
            <h1>Home</h1>

            <input type="text" className="form-control mb-3" placeholder="Search..." onChange={this.handleSearch.bind(this)} />

            <div className="row">
                <div className="col">
                    <form onSubmit={this.add_pb.bind(this)}>
                        <div className="form-group">
                            <label>Name</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Name" 
                                name="name" 
                                value={form.name} 
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label>Name (Arabic)</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Name (Arabic)" 
                                name="name_ar" 
                                value={form.name_ar} 
                                onChange={this.changeHandler}
                            />
                        </div>

                        <div className="form-group">
                            {/* <label>Image</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                name="image" 
                                value={form.image} 
                                onChange={this.changeHandler}
                            /> */}

                            <ImageUploader
                                ref="ImageUploader"
                                withIcon={false}
                                buttonText='Choose Picture'
                                withLabel={false}
                                onChange={this.onDrop.bind(this)}
                                imgExtension={['.jpg', '.gif', '.png']}
                                maxFileSize={5242880}
                                withPreview={true}
                                maxFiles={10}
                            />
                        </div>
                        <button type="submit" disabled={(form.name === '' || form.name_ar === '') ? true 
                        : false} className="btn btn-primary">Submit</button>
                    </form>
                </div>

                <div className="col-8">
                    <FlipMove enterAnimation="accordionVertical" leaveAnimation="accordionVertical">
                    {filtered.sort((a, b) => b.votes - a.votes).map((item, index) => (

                        <div className="dash-candidate row align-items-center mb-2" key={item._id}>
                            {item.image && (
                                <div className="col-2">
                                    <div className="thumb">
                                        <img src={item.image} alt="" />
                                    </div>
                                </div>
                            )}
                            <div className="col">
                                <h3>{index + 1} {item.name}</h3>
                                <h3>{item.name_ar}</h3>
                            </div>
                            <div className="col-2"><h1>{item.votes}</h1></div>
                            <div className="col-2">
                                <div className="btn-group">
                                    <button className="btn btn-default" onClick={() => this.rem_vote(item._id)}>-</button>
                                    <button className="btn btn-default" onClick={() => this.add_vote(item._id)}>+</button>
                                </div>
                            </div>
                            <div className="col-2">
                                <button className="btn btn-danger" onClick={() => this.remove_pb(item._id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                    </FlipMove>
                </div>
            </div>
            </div>
            
        );
    }
}

import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Home from './Pages/Home'

import Board from './Pages/Board'
import BoardPB from './Pages/Board-pb'
import BoardECO from './Pages/Board-eco'

import Dash from './Pages/Dash'
import DashPB from './Pages/Dash-pb'
import DashECO from './Pages/Dash-eco'

import Vote from './Pages/Vote'
import VotePB from './Pages/Vote-pb'
import VoteECO from './Pages/Vote-eco'


export default () => (
    <BrowserRouter>
        <Switch>
            <Route path="/" exact component={Board} />
            <Route path="/board/pb" exact component={BoardPB} />
            <Route path="/board/eco" exact component={BoardECO} />

            <Route path="/dash" exact component={Dash} />
            <Route path="/dash/pb" exact component={DashPB} />
            <Route path="/dash/eco" exact component={DashECO} />

            <Route path="/vote" exact component={Vote} />
            <Route path="/vote/pb" exact component={VotePB} />
            <Route path="/vote/eco" exact component={VoteECO} />

            <Route path="/about" exact render={() => <h1>About</h1>} />
            <Route path="/intro" exact component={Home} />
        </Switch>
    </BrowserRouter>
)

import React from 'react';
// import logo from '../assets/img/logo.png';
import logoW from '../assets/img/logo-white.png';

import settingsIcon from '../assets/icons/settings-white.svg'
import voteIcon from '../assets/icons/vote-white.svg'
import BitwizeLogo from '../assets/img/bitwize-logo-white.svg'

import {Link} from 'react-router-dom'

export default () => (
    <div className="full-page flex-center bg-grey">
        <a className="bitwize" href="https://bitwize.com.lb" target="_blank" rel="noopener noreferrer"><img src={BitwizeLogo} alt="" /></a>
        <div className="container p-5">
            <div className="text-center">
                <img src={logoW} alt="logo" style={{maxWidth: 300}} />
                <h1 className="text-white text-xxl mt-5">نتائج الانتخابات</h1>
                <div className="my-5">
                    <Link to="/board/pb" className="intro-btn"> المكتب السياسي</Link>
                </div>
                {/* <div>
                    <Link to="/board/eco" className="intro-btn"> اللجنة العليا للرقابة المالية</Link>
                </div> */}
            </div>
            
        </div>
        <div className="bottom-left">
            <Link to="/dash/pb" className="svgicon mr-4"><img src={settingsIcon} alt="" /></Link>
            <Link to="/vote/pb" className="svgicon"><img src={voteIcon} alt="" /></Link>
        </div>
    </div>
);
